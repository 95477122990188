import { MyImage } from "../components/MyImage";
import whiteBook from "../assets/white_book_1024.png";
import whiteReedsTransparentBackground from "../assets/white_reeds_transparent_background_with_frame.png";
import whiteReedsTransparent from "../assets/white-transparent-reeds.png";
import whiteBookTransparentBackground from "../assets/reedbook-transparent-with-some-extra-blue.png";
import blueBook from "../assets/reedbook-blue-cover-transparent.png";
import Colors from "../constants/Colors";
import { Outlet, Link } from "react-router-dom";

export default function Home() {
  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        minHeight: "100vh",
        backgroundColor: "green",
      }}
    >
      <div
        style={{
          paddingTop: 40,
          paddingBottom: 40,
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignSelf: "center",
          justifyContent: "center",
          backgroundColor: Colors.primary,
        }}
      >
        <img
          src={whiteBookTransparentBackground}
          height={75}
          width={75}
          style={{ alignSelf: "center" }}
        />
        <h1
          style={{ color: Colors.white, alignSelf: "center", marginLeft: 30 }}
        >
          Reedbook
        </h1>
      </div>

      {/* <h1 style="padding-bottom: 10px">Reedbook</h1> */}
      {/* <h1 style={{ color: Colors.white, alignSelf: "center" }}>Reedbook</h1> */}

      <div
        style={{
          // display: "flex",
          flexGrow: 1,
          // height: 200,
          backgroundColor: Colors.white,
        }}
      >
        <p
          style={{
            color: Colors.primary_dark,
            backgroundColor: Colors.white,
            alignSelf: "center",
            fontSize: 20,
            padding: 20,
            paddingRight: 40,
            paddingLeft: 40,
            textAlign: "center",
          }}
        >
          {`Reedbook is a Mobile Application for Android and iOS that allows you
          to log reeds, share them with followers, and learn from your favorite reedmakers!`}
        </p>

        <div
          style={{
            // justifyContent: "center",
            display: "flex",
            flexWrap: "wrap",
            flexDirection: "column",
            alignContent: "center",
            // al
          }}
        >
          <a
            href="https://apps.apple.com/us/app/reedbook/id6466372006"
            target="_blank"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: 20,
                flexWrap: "wrap",
                alignItems: "center",
              }}
            >
              <img src={blueBook} height={75} width={75} />
              <h3>Download Reedbook for iOS</h3>
            </div>
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.reedbook"
            target="_blank"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                marginTop: 20,
                alignItems: "center",
              }}
            >
              <img src={blueBook} height={75} width={75} />
              <h3>Download Reedbook for Android</h3>
            </div>
          </a>
        </div>
        <p
          style={{
            color: Colors.primary_dark,
            backgroundColor: Colors.white,
            alignSelf: "center",
            fontSize: 20,
            padding: 20,
            paddingRight: 40,
            paddingLeft: 40,
            textAlign: "center",
          }}
        >
          Developed by an oboist, this app is designed for oboists, bassoonists,
          clarinetists, saxophonists, and any other instrumentalist that is
          interested in making reeds.
        </p>

        {/* <div
          style={{
            display: "flex",
            // alignItems: "center",
            flexDirection: "column",
            paddingLeft: 40,
            paddingRight: 40,
            paddingBottom: 40,
          }}
        >

          <ul
            style={{
              fontSize: 20,
              color: Colors.primary_dark,
              marginBottom: 10,
            }}
          >
            <li style={{ marginBottom: listMargin }}>Record Audio</li>
            <li style={{ marginBottom: listMargin }}>Take Pictures</li>
            <li style={{ marginBottom: listMargin }}>
              Record reed data of your own choosing
            </li>
            <li style={{ marginBottom: listMargin }}>
              Save custom reed models and quickly use them to log your reeds
            </li>
            <li style={{ marginBottom: listMargin }}>
              Make your reeds public so others can see them
            </li>
            <li style={{ marginBottom: listMargin }}>
              {" "}
              Global Reed Feed - see other reeds people are making{" "}
            </li>
            <li style={{ marginBottom: listMargin }}>
              Following Reed Feed - see reeds of the people you are following{" "}
            </li>
            <li style={{ marginBottom: listMargin }}>
              Follow other users - you can follow your teacher or favorite
              reedmakers
            </li>
            <li style={{ marginBottom: listMargin }}>
              Build a following - ask your students to follow you so they can
              see your reeds
            </li>
            <li style={{ marginBottom: listMargin }}>
              User Search - search for friends, teachers, students, and view
              their reed logs
            </li>
            <li style={{ marginBottom: listMargin }}>
              Profile - edit your profile to include information about you,
              including a link to your own website.
            </li>
          </ul>
        </div> */}
      </div>
      <div
        style={{
          paddingTop: 20,
          display: "flex",
          width: "100%",
          flexDirection: "row",
          // alignSelf: "flex-end",
          alignContent: "space-between",
          flexWrap: "wrap",
          justifyContent: "space-around",
          backgroundColor: Colors.primary,
        }}
      >
        <Link
          style={{ color: Colors.white, paddingBottom: 20 }}
          to={`/contactUs`}
        >
          Contact Us
        </Link>
        <Link style={{ color: Colors.white, paddingBottom: 20 }} to={`/eula`}>
          End User License Agreement
        </Link>
        <Link style={{ color: Colors.white, paddingBottom: 20 }} to={`/gdpr`}>
          GDPR Privacy Policy
        </Link>
        <Link
          style={{ color: Colors.white, paddingBottom: 20 }}
          to={`/changeLog`}
        >
          Change Log
        </Link>
      </div>
      <div
        style={{
          backgroundColor: Colors.primary,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            color: Colors.white,
            textAlign: "center",
            marginRight: 20,
            fontSize: 12,
          }}
        >
          Copyright © 2023, All Rights Reserved
        </p>
      </div>
    </div>
  );

  return (
    <>
      <div id="sidebar">
        <h1>Reedbook</h1>

        <div>
          <form id="search-form" role="search">
            <input
              id="q"
              aria-label="Search contacts"
              placeholder="Search"
              type="search"
              name="q"
            />
            <div id="search-spinner" aria-hidden hidden={true} />
            <div className="sr-only" aria-live="polite"></div>
          </form>
          <form method="post">
            <button type="submit">New</button>
          </form>
        </div>
        <nav>
          <ul>
            <li>
              <a href={`/contacts/1`}>Your Name</a>
            </li>
            <li>
              <a href={`/contacts/2`}>Your Friend</a>
            </li>
          </ul>
        </nav>
      </div>
      <div id="detail"></div>
    </>
  );
}

const listMargin = 5;
