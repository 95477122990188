import { FormEventHandler, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { Button } from "../components/Button";
import Colors from "../constants/Colors";
import whiteBookTransparentBackground from "../assets/reedbook-transparent-with-some-extra-blue.png";
import blueBook from "../assets/reedbook-blue-cover-transparent.png";
import "./Auth.css";
import {
  getAuth,
  applyActionCode,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from "firebase/auth";

import { Spinner } from "../components/Spinner";
import { Verify } from "crypto";
import { isMap } from "util/types";

const QUERY_PARAM_MODE = "mode";
const QUERY_PARAM_OOB_CODE = "oobCode";
const QUERY_PARAM_API_KEY = "apiKey";
const QUERY_PARAM_LANG = "lang";
const QUERY_PARAM_CONTINUE_URL = "continueUrl";

const MODE_VERIFY_EMAIL = "verifyEmail";
const MODE_RESET_PASSWORD = "resetPassword";

export default function Auth() {
  const auth = getAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const [newPassword, setNewPassword] = useState<string | undefined>(undefined);

  const mode = searchParams.get(QUERY_PARAM_MODE);
  const oobCode = searchParams.get(QUERY_PARAM_OOB_CODE);

  const [verifyEmailState, setVerifyEmailState] = useState<
    "loading" | "verified" | "error"
  >("loading");

  const [resetPasswordState, setResetPasswordState] = useState<
    ResetPasswordState | undefined
  >();

  const [resetPasswordEmailAddress, setResetPasswordEmailAddress] = useState<
    string | undefined
  >();

  useEffect(() => {
    if (mode == MODE_VERIFY_EMAIL) {
      const verifyEmail = async () => {
        if (!oobCode) {
          setVerifyEmailState("error");
          return;
        }

        setVerifyEmailState("loading");
        try {
          await applyActionCode(auth, oobCode);
          setVerifyEmailState("verified");
        } catch (e) {
          setVerifyEmailState("error");
        }
      };
      verifyEmail();
    } else if (mode == MODE_RESET_PASSWORD) {
      const resetPassword = async () => {
        if (!oobCode) {
          setResetPasswordState(
            ResetPasswordState.VerifyingPasswordResetCodeError
          );
          return;
        }

        setResetPasswordState(ResetPasswordState.VerifyingPasswordResetCode);
        try {
          const userEmailAddress = await verifyPasswordResetCode(auth, oobCode);
          setResetPasswordEmailAddress(userEmailAddress);
          setResetPasswordState(ResetPasswordState.SubmitNewPassword);
        } catch (e) {
          setResetPasswordState(
            ResetPasswordState.VerifyingPasswordResetCodeError
          );
        }
      };
      resetPassword();
    }
  }, [mode, auth, oobCode]);

  async function onSubmitResetPassword() {
    setResetPasswordState(ResetPasswordState.ResettingPassword);
    try {
      if (!newPassword) {
        return;
      }
      if (!oobCode) {
        setResetPasswordState(ResetPasswordState.ResetPasswordError);
        return;
      }
      const result = await confirmPasswordReset(auth, oobCode, newPassword);

      setResetPasswordState(ResetPasswordState.ResetPasswordSuccess);
    } catch (e) {
      setResetPasswordState(ResetPasswordState.ResetPasswordError);
    }
  }

  function handleSubmit(e: any) {
    // alert("A name was submitted: " + newPassword);
    e.preventDefault();
  }

  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        minHeight: "100vh",
        backgroundColor: "green",
      }}
    >
      <Link
        style={{
          paddingTop: 40,
          paddingBottom: 40,
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignSelf: "center",
          justifyContent: "center",
          backgroundColor: Colors.primary,
          textDecoration: "none",
        }}
        to={`/`}
      >
        <img
          src={whiteBookTransparentBackground}
          height={75}
          width={75}
          style={{ alignSelf: "center" }}
        />
        <h1
          style={{ color: Colors.white, alignSelf: "center", marginLeft: 30 }}
        >
          {`Reedbook`}
        </h1>
      </Link>

      <div
        style={{
          display: "flex",
          flexGrow: 1,
          backgroundColor: Colors.white,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {mode == MODE_VERIFY_EMAIL ? (
          <VerifyingEmail state={verifyEmailState} />
        ) : null}

        {mode == MODE_RESET_PASSWORD && resetPasswordState ? (
          <ResetPasswordComponent
            state={resetPasswordState}
            emailAddress={resetPasswordEmailAddress}
            onSubmit={onSubmitResetPassword}
            newPassword={newPassword}
            setNewPassword={(string) => {
              setNewPassword(string);
            }}
          />
        ) : null}
      </div>
      <div
        style={{
          paddingTop: 20,
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignContent: "space-between",
          flexWrap: "wrap",
          justifyContent: "space-around",
          backgroundColor: Colors.primary,
        }}
      >
        <Link
          style={{ color: Colors.white, paddingBottom: 20 }}
          to={`/contactUs`}
        >
          Contact Us
        </Link>
        <Link style={{ color: Colors.white, paddingBottom: 20 }} to={`/eula`}>
          End User License Agreement
        </Link>
        <Link style={{ color: Colors.white, paddingBottom: 20 }} to={`/gdpr`}>
          GDPR Privacy Policy
        </Link>
      </div>
      <div
        style={{
          backgroundColor: Colors.primary,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            color: Colors.white,
            textAlign: "center",
            marginRight: 20,
            fontSize: 12,
          }}
        >
          Copyright © 2023, All Rights Reserved
        </p>
      </div>
    </div>
  );
}

type VerifyEmailProps = {
  state: "verified" | "loading" | "error";
};

function VerifyingEmail(props: VerifyEmailProps) {
  const textToDisplay =
    props.state == "loading"
      ? "Verifying email ..."
      : props.state == "verified"
      ? "Your email address has been verified.\n\nYou may now continue to use the Reedbook app."
      : "Sorry, something went wrong trying to verify you email address.  Your code may be invalid or expired.  Please go through flow to verify email again.";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: 40,
        paddingRight: 40,
        alignItems: "center",
      }}
    >
      <h2
        style={{
          alignSelf: "center",
          color: Colors.primary_dark,
          paddingBottom: 40,
          textAlign: "center",
        }}
      >
        {textToDisplay}
      </h2>
      {props.state == "loading" ? <Spinner /> : null}
    </div>
  );
}

type ResetPasswordComponentProps = {
  state: ResetPasswordState;
  emailAddress: string | undefined;
  onSubmit: () => void;
  newPassword: string | undefined;
  setNewPassword: (newPassword: string) => void;
};

function ResetPasswordComponent(props: ResetPasswordComponentProps) {
  const textToDisplay =
    props.state == ResetPasswordState.VerifyingPasswordResetCode
      ? "Verifying reset password code..."
      : props.state == ResetPasswordState.VerifyingPasswordResetCodeError
      ? "Error: Invalid or Expired code.  Please go through reset password flow again."
      : props.state == ResetPasswordState.SubmitNewPassword &&
        props.emailAddress
      ? `Please submit a new password for your ${props.emailAddress} account.`
      : props.state == ResetPasswordState.SubmitNewPassword
      ? "Please submit a new password."
      : props.state == ResetPasswordState.ResettingPassword
      ? "Resetting password ..."
      : props.state == ResetPasswordState.ResetPasswordSuccess
      ? "Congratulations, you have reset your password.\n\nYou may now log in to the app with your new password."
      : props.state == ResetPasswordState.ResetPasswordError
      ? "Sorry, something went wrong.  Either your password is too weak or your reset password code has expired and you need to go through the reset password flow again."
      : "Sorry, something went wrong trying to reset your password.";

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: 40,
        paddingRight: 40,
        alignItems: "center",
      }}
    >
      <h2
        style={{
          alignSelf: "center",
          color: Colors.primary_dark,
          paddingBottom: 40,
          textAlign: "center",
        }}
      >
        {textToDisplay}
      </h2>

      {props.state == ResetPasswordState.SubmitNewPassword ||
      props.state == ResetPasswordState.ResettingPassword ||
      props.state == ResetPasswordState.ResetPasswordError ? (
        <div style={{ display: "flex", alignSelf: "center" }}>
          <form
            onSubmit={() => {
              props.onSubmit();
            }}
            style={{ display: "flex", flexDirection: "column" }}
          >
            <label style={{ marginRight: 10, color: Colors.primary_dark }}>
              New Password:
              <input
                style={{ marginLeft: 10, color: Colors.primary_dark }}
                type="text"
                value={props.newPassword}
                onChange={(e) => {
                  props.setNewPassword(e.target.value);
                }}
              />
            </label>
            <input
              type="button"
              value="Submit"
              onSubmit={() => {
                props.onSubmit();
              }}
              onClick={() => {
                props.onSubmit();
              }}
              style={{
                color: Colors.white,
                marginTop: 20,
                border: 0,
                borderRadius: 5,
                minHeight: 30,
              }}
            />
          </form>
        </div>
      ) : null}
      {props.state == "verifyingPasswordResetCode" ||
      props.state == "resettingPassword" ? (
        <Spinner />
      ) : null}
    </div>
  );
}
export enum ActivityStatusEnum {
  Complete = "COMPLETE",
  Pending = "PENDING",
}

enum ResetPasswordState {
  VerifyingPasswordResetCode = "verifyingPasswordResetCode",
  VerifyingPasswordResetCodeError = "verifyingPasswordResetCodeError",
  SubmitNewPassword = "submitNewPassword",
  ResettingPassword = "resettingPassword",
  ResetPasswordSuccess = "resetPasswordSuccess",
  ResetPasswordError = "resettingPasswordError",
}
