const light_grey = "rgb(230,230,230)";
const light_medium_grey = "rgb(180,180,180)";
const medium_grey = "rgb(120,120,120)";
const dark_gray = "rgb(80,80,80)";

export default {
  primary: "#052E66",
  primary_dark: "#032D55",
  primary_with_opacity: "#10052E66",
  accent: "#40B0D0",
  lighter_accent: "#CC8fe0f7",
  white: "#ffffff",
  white_50_opacity: "#80ffffff",
  red: "#ff0000",
  dark_red: "rgb(200,0,0)",
  black: "#000",
  black_20_opacity: "rgba(0,0,0,.2)",
  filled_input_text_background_color: light_grey,
  light_grey: light_grey,
  light_medium_grey: light_medium_grey,
  medium_grey: medium_grey,
  dark_grey: dark_gray,
  transparent: "transparent",
  // link_color: "rgb(6,69,173)",
  link_color: "blue",
  gold: "gold",
};
