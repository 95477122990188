import { Link, useRouteError } from "react-router-dom";
import Colors from "../constants/Colors";
import whiteBookTransparentBackground from "../assets/reedbook-transparent-with-some-extra-blue.png";

export default function ErrorScreen() {
  const error = useRouteError() as any;
  console.error(error);

  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        minHeight: "100vh",
        backgroundColor: "green",
      }}
    >
      <Link
        style={{
          paddingTop: 40,
          paddingBottom: 40,
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignSelf: "center",
          justifyContent: "center",
          backgroundColor: Colors.primary,
          textDecoration: "none",
        }}
        to={`/`}
      >
        <img
          src={whiteBookTransparentBackground}
          height={75}
          width={75}
          style={{ alignSelf: "center" }}
        />
        <h1
          style={{ color: Colors.white, alignSelf: "center", marginLeft: 30 }}
        >
          Reedbook
        </h1>
      </Link>

      <div
        style={{
          flexGrow: 1,
          backgroundColor: Colors.white,
        }}
      >
        <div
          id="error-page"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h1 style={{ color: Colors.primary_dark }}>Oops!</h1>
          <p style={{ color: Colors.primary_dark }}>
            Sorry, an unexpected error has occurred.
          </p>
          <p style={{ color: Colors.primary_dark }}>
            <i>{error.statusText || error.message}</i>
          </p>
        </div>
      </div>
      <div
        style={{
          paddingTop: 20,
          display: "flex",
          width: "100%",
          flexDirection: "row",
          // alignSelf: "flex-end",
          alignContent: "space-between",
          flexWrap: "wrap",
          justifyContent: "space-around",
          backgroundColor: Colors.primary,
        }}
      >
        <Link
          style={{ color: Colors.white, paddingBottom: 20 }}
          to={`/contactUs`}
        >
          Contact Us
        </Link>
        <Link style={{ color: Colors.white, paddingBottom: 20 }} to={`/eula`}>
          End User License Agreement
        </Link>
        <Link style={{ color: Colors.white, paddingBottom: 20 }} to={`/gdpr`}>
          GDPR Privacy Policy
        </Link>
      </div>
      <div
        style={{
          backgroundColor: Colors.primary,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            color: Colors.white,
            textAlign: "center",
            marginRight: 20,
            fontSize: 12,
          }}
        >
          Copyright © 2023, All Rights Reserved
        </p>
      </div>
    </div>
  );
}
