import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../components/Button";
import Colors from "../constants/Colors";
import whiteBookTransparentBackground from "../assets/reedbook-transparent-with-some-extra-blue.png";
import blueBook from "../assets/reedbook-blue-cover-transparent.png";

export default function EULA() {
  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        minHeight: "100vh",
        backgroundColor: "green",
      }}
    >
      <Link
        style={{
          paddingTop: 40,
          paddingBottom: 40,
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignSelf: "center",
          justifyContent: "center",
          backgroundColor: Colors.primary,
          textDecoration: "none",
        }}
        to={`/`}
      >
        <img
          src={whiteBookTransparentBackground}
          height={75}
          width={75}
          style={{ alignSelf: "center" }}
        />
        <h1
          style={{ color: Colors.white, alignSelf: "center", marginLeft: 30 }}
        >
          Reedbook
        </h1>
      </Link>

      <div
        style={{
          // display: "flex",
          flexGrow: 1,
          height: 200,
          overflowY: "scroll",
          backgroundColor: Colors.white,
        }}
      >
        <EULAText />
      </div>
      <div
        style={{
          paddingTop: 20,
          display: "flex",
          width: "100%",
          flexDirection: "row",
          // alignSelf: "flex-end",
          alignContent: "space-between",
          flexWrap: "wrap",
          justifyContent: "space-around",
          backgroundColor: Colors.primary,
        }}
      >
        <Link
          style={{ color: Colors.white, paddingBottom: 20 }}
          to={`/contactUs`}
        >
          Contact Us
        </Link>
        <Link style={{ color: Colors.white, paddingBottom: 20 }} to={`/eula`}>
          End User License Agreement
        </Link>
        <Link style={{ color: Colors.white, paddingBottom: 20 }} to={`/gdpr`}>
          GDPR Privacy Policy
        </Link>
        <Link
          style={{ color: Colors.white, paddingBottom: 20 }}
          to={`/changeLog`}
        >
          Change Log
        </Link>
      </div>
      <div
        style={{
          backgroundColor: Colors.primary,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            color: Colors.white,
            textAlign: "center",
            marginRight: 20,
            fontSize: 12,
          }}
        >
          Copyright © 2023, All Rights Reserved
        </p>
      </div>
    </div>
  );
}

function EULAText() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: 40,
        paddingRight: 40,
        alignItems: "center",
      }}
    >
      <h1 style={{ color: Colors.primary_dark, marginBottom: 20 }}>
        End-User License Agreement
      </h1>
      <p>
        End-User License Agreement (EULA) of Reedbook software. This End-User
        License Agreement ("EULA") is a legal agreement between you and Jeffrey
        S. Padgett. This EULA agreement governs your acquisition and use of
        Reedbook software ("Software") directly from Jeffrey S. Padgett or
        indirectly through a Jeffrey S. Padgett authorized reseller or
        distributor (a "Reseller"). Please read this EULA agreement carefully
        before completing the installation process and using the Reedbook
        software. It provides a license to use the Reedbook software and
        contains warranty information and liability disclaimers. By installing
        and/or using the Reedbook software, you are confirming your acceptance
        of the Software and agreeing to become bound by the terms of this EULA
        agreement. If you are entering into this EULA agreement on behalf of a
        company or other legal entity, you represent that you have the authority
        to bind such entity and its affiliates to these terms and conditions. If
        you do not have such authority or if you do not agree with the terms and
        conditions of this EULA agreement, do not install or use the Software,
        and you must not accept this EULA agreement. This EULA agreement shall
        apply only to the Software supplied by Jeffrey S. Padgett herewith
        regardless of whether other software is referred to or described herein.
        The terms also apply to any Jeffrey S. Padgett updates, supplements,
        Internet-based services, and support services for the Software, unless
        other terms accompany those items on delivery. If so, those terms apply.
        License Grant Jeffrey S. Padgett hereby grants you a personal,
        non-transferable, non-exclusive license to use the Reedbook software on
        your devices in accordance with the terms of this EULA agreement. You
        are permitted to load the Reedbook software on a device under your
        control. You are responsible for ensuring your device meets the minimum
        requirements of the Reedbook software. You are not permitted to: - Edit,
        alter, modify, adapt, translate or otherwise change the whole or any
        part of the Software nor permit the whole or any part of the Software to
        be combined with or become incorporated in any other software, nor
        decompile, disassemble or reverse engineer the Software or attempt to do
        any such things; - Reproduce, copy, distribute, resell or otherwise use
        the Software for any commercial purpose; - Allow any third party to use
        the Software on behalf of or for the benefit of any third party; - Use
        the Software in any way which breaches any applicable local, national or
        international law; - use the Software for any purpose that Jeffrey S.
        Padgett considers is a breach of this EULA agreement. ​ Intellectual
        Property and Ownership Jeffrey S. Padgett shall at all times retain
        ownership of the Software as originally downloaded by you and all
        subsequent downloads of the Software by you. The Software (and the
        copyright, and other intellectual property rights of whatever nature in
        the Software, including any modifications made thereto) are and shall
        remain the property of Jeffrey S. Padgett. Jeffrey S. Padgett reserves
        the right to grant licenses to use the Software to third parties.
        Jeffrey S. Padgett reserves the right to modify, suspend or discontinue,
        temporarily or permanently, the Application or any service to which it
        connects, with or without notice and without liability to you.
        Termination This EULA agreement is effective from the date you first use
        the Software and shall continue until terminated. This Agreement shall
        remain in effect until terminated by you or Jeffrey S. Padgett. Jeffrey
        S. Padgett may, at his sole discretion, at any time and for any or no
        reason, suspend or terminate this Agreement with or without prior
        notice. It will also terminate immediately if you fail to comply with
        any term of this EULA agreement. Upon such termination, the licenses
        granted by this EULA agreement will immediately terminate and you agree
        to stop all access and use of the Software. The provisions that by their
        nature continue and survive will survive any termination of this EULA
        agreement. Severability ​ If any provision of this Agreement is held to
        be unenforceable or invalid, such provision will be changed and
        interpreted to accomplish the objectives of such provision to the
        greatest extent possible under applicable law and the remaining
        provisions will continue in full force and effect.Governing Law
        Governing Law ​ This EULA agreement, and any dispute arising out of or
        in connection with this EULA agreement, shall be governed by and
        construed in accordance with the laws of the United States.
      </p>
    </div>
  );
}
