import React from "react";
import "./App.css";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { initializeApp } from "firebase/app";
import Home from "./routes/Home";
import ErrorScreen from "./components/ErrorScreen";
import ContactUs from "./routes/ContactUs";
import ChangeLog from "./routes/ChangeLog";
import GDPR from "./routes/GDPR";
import EULA from "./routes/EULA";
import Auth from "./routes/Auth";

function App() {
  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional
  const firebaseConfig = {
    apiKey: "AIzaSyByaYcrcvTqO7-ECX4bU7mlueTWrBjxwVA",
    authDomain: "reed-book.firebaseapp.com",
    projectId: "reed-book",
    storageBucket: "reed-book.appspot.com",
    messagingSenderId: "447368392418",
    appId: "1:447368392418:web:07bf048bebea663402f8bb",
    measurementId: "G-E2TQLJ7GPC",
  };

  const app = initializeApp(firebaseConfig);

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home />,
      errorElement: <ErrorScreen />,
    },
    {
      path: "contactUs",
      element: <ContactUs />,
    },
    {
      path: "changeLog",
      element: <ChangeLog />,
    },
    {
      path: "gdpr",
      element: <GDPR />,
    },
    {
      path: "eula",
      element: <EULA />,
    },
    {
      path: "auth",
      element: <Auth />,
    },
  ]);

  return (
    <RouterProvider router={router} />

    // <div className="App">
    //   <header className="App-header">
    //     <img src={logo} className="App-logo" alt="logo" />
    //     <p>
    //       Edit <code>src/App.tsx</code> and save to reload. what up
    //     </p>
    //     <a
    //       className="App-link"
    //       href="https://reactjs.org"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       Learn React
    //     </a>
    //   </header>
    // </div>
  );
}

export default App;
