import { Link } from "react-router-dom";
import Colors from "../constants/Colors";
import whiteBookTransparentBackground from "../assets/reedbook-transparent-with-some-extra-blue.png";

export default function ChangeLog() {
  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        minHeight: "100vh",
        backgroundColor: "green",
      }}
    >
      <Link
        style={{
          paddingTop: 40,
          paddingBottom: 40,
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignSelf: "center",
          justifyContent: "center",
          backgroundColor: Colors.primary,
          textDecoration: "none",
        }}
        to={`/`}
      >
        <img
          src={whiteBookTransparentBackground}
          height={75}
          width={75}
          style={{ alignSelf: "center" }}
        />
        <h1
          style={{ color: Colors.white, alignSelf: "center", marginLeft: 30 }}
        >
          Reedbook
        </h1>
      </Link>

      <div
        style={{
          // display: "flex",
          flexGrow: 1,
          height: 200,
          backgroundColor: Colors.white,
        }}
      >
        <ChangeLogCenterView />
      </div>
      <div
        style={{
          paddingTop: 20,
          display: "flex",
          width: "100%",
          flexDirection: "row",
          // alignSelf: "flex-end",
          alignContent: "space-between",
          flexWrap: "wrap",
          justifyContent: "space-around",
          backgroundColor: Colors.primary,
        }}
      >
        <Link
          style={{ color: Colors.white, paddingBottom: 20 }}
          to={`/contactUs`}
        >
          Contact Us
        </Link>
        <Link style={{ color: Colors.white, paddingBottom: 20 }} to={`/eula`}>
          End User License Agreement
        </Link>
        <Link style={{ color: Colors.white, paddingBottom: 20 }} to={`/gdpr`}>
          GDPR Privacy Policy
        </Link>
        <Link
          style={{ color: Colors.white, paddingBottom: 20 }}
          to={`/changeLog`}
        >
          Change Log
        </Link>
      </div>
      <div
        style={{
          backgroundColor: Colors.primary,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            color: Colors.white,
            textAlign: "center",
            marginRight: 20,
            fontSize: 12,
          }}
        >
          Copyright © 2023, All Rights Reserved
        </p>
      </div>
    </div>
  );
}

function ChangeLogCenterView() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: 40,
        paddingRight: 40,
        alignItems: "flex-start",
        overflowY: "auto",
        overflowX: "auto",
        height: "100%",
      }}
    >
      <h1 style={{ alignSelf: "center", color: Colors.primary_dark }}>
        Change Log
      </h1>

      <h2 style={{ color: Colors.primary_dark }}>2.0.1</h2>
      <ul>
        <li>
         Fix bug that did not let you edit a reed that had likes or comments  </li>
        <li>
          Fix bug that did not let you comment on a reed that had 0 comments
        </li>
      </ul>

      <h2 style={{ color: Colors.primary_dark }}>2.0.0</h2>
      <ul>
        <li>
          You can now comment on reeds!  Do you have a question, need some recommendations, or want to encourage someone?  Leave a comment, and a notification will be sent!
        </li>
        <li>
        Comment on public reeds.  Private reeds are still not visible to other people using Reedbook, and cannot be commented on.
        </li>
        <li>
        See all your notifications by clicking on the new notifications tab.  It tracks all of your past notifications, and tapping each notification opens to the correct place in the app.
        </li>
        <li>
        Receive a notification when someone comments on your reed.
        </li>
        <li>
        Receive a notification when someone comments on a reed that you commented on.
        </li>
        <li>
        Receive a notification when someone comments on a reed that you liked.
        </li>
      </ul>

      <h2 style={{ color: Colors.primary_dark }}>1.0.8</h2>
      <ul>
        <li>
          You can now "Like" a reed! When you like a reed, a notification will
          be sent to the reed maker's phone/device. If your reed is liked, a
          notification will be sent to you.
        </li>
        <li>
          If you tap on a notification, it will open the app to the appropriate
          place in the app. (ie, if your notification said someone created a
          reed, it will open their reed details.)
        </li>
        <li>
          Search User requirements reduced - you can now search for users by
          entering a minimum of 1 character, instead of a minimum of 3
          characters. Remember that someone's name starts with "A" but not sure
          what else? Just search "A" in user search!
        </li>
        <li>
          Some minor UI improvements (toggle button, some margins/padding on
          details page)
        </li>
      </ul>

      <h2 style={{ color: Colors.primary_dark }}>1.0.7</h2>
      <ul>
        <li>
          Now, when creating or editing a reed, if you have unsaved changes you
          will be prompted to save or discard changes.
        </li>
        <li>
          Fixes bug where blank images were appearing white on details screen.
        </li>
        <li>
          Fixes bug on Reed Details screen where corners of images had gaps/
          different sizes.
        </li>
        <li>
          Fixes bug where pressing stop on play button while playing audio would
          set progress bar to 0 instead of full.
        </li>
        <li>
          Fixes bug where there was flickering of blank image on reed details
          screen, and reed attributes would jump.
        </li>
      </ul>

      <h2 style={{ color: Colors.primary_dark }}>1.0.6</h2>
      <ul>
        <li>Skipped (replaced quickly with 1.0.7)</li>
      </ul>

      <h2 style={{ color: Colors.primary_dark }}>1.0.5</h2>
      <ul>
        <li>
          Allow user to select a reed's display photo by long pressing photo.
        </li>
        <li>
          Fix issue where iOS would not play the reed audio if in silent mode.
        </li>
      </ul>

      <h2 style={{ color: Colors.primary_dark }}>1.0.4</h2>
      <ul>
        <li>
          Select reed or profile images from your camera photo gallery, or take
          directly with your camera!
        </li>
        <li>
          Reorder any of your custom reed models by dragging and dropping the
          attributes into the order you want!
        </li>
        <li>Added Account Settings Screen with ability to Delete Account</li>
        <li>Rearranged Settings Screen</li>
        <li>Minor performance/speed improvements</li>
      </ul>

      <h2 style={{ color: Colors.primary_dark }}>1.0.3</h2>
      <ul>
        <li>Bug fix for editing your custom reed models.</li>
      </ul>

      <h2 style={{ color: Colors.primary_dark }}>1.0.2</h2>
      <ul>
        <li>Notifications!</li>
        <li>When a user follows you, you will receive a notification.</li>
        <li>
          When someone you follow makes a reed, you will receive a notification.
        </li>
        <li>
          If you want to customize these settings, you can go to the Settings
          screen and toggle them on and off as you wish.
        </li>
      </ul>

      <h2 style={{ color: Colors.primary_dark }}>1.0.0</h2>

      <ul>
        <li>Initial Release</li>
      </ul>
    </div>
  );
}
