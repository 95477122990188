import { useState } from "react";
import { Link } from "react-router-dom";
import { Button } from "../components/Button";
import Colors from "../constants/Colors";
import whiteBookTransparentBackground from "../assets/reedbook-transparent-with-some-extra-blue.png";
import blueBook from "../assets/reedbook-blue-cover-transparent.png";

function onSubmit() {}
export default function ContactUs() {
  return (
    <div
      style={{
        flexDirection: "column",
        display: "flex",
        minHeight: "100vh",
        backgroundColor: "green",
      }}
    >
      <Link
        style={{
          paddingTop: 40,
          paddingBottom: 40,
          display: "flex",
          width: "100%",
          flexDirection: "row",
          alignSelf: "center",
          justifyContent: "center",
          backgroundColor: Colors.primary,
          textDecoration: "none",
        }}
        to={`/`}
      >
        <img
          src={whiteBookTransparentBackground}
          height={75}
          width={75}
          style={{ alignSelf: "center" }}
        />
        <h1
          style={{ color: Colors.white, alignSelf: "center", marginLeft: 30 }}
        >
          Reedbook
        </h1>
      </Link>

      <div
        style={{
          // display: "flex",
          flexGrow: 1,
          height: 200,
          backgroundColor: Colors.white,
        }}
      >
        <ContactUsForm />
      </div>
      <div
        style={{
          paddingTop: 20,
          display: "flex",
          width: "100%",
          flexDirection: "row",
          // alignSelf: "flex-end",
          alignContent: "space-between",
          flexWrap: "wrap",
          justifyContent: "space-around",
          backgroundColor: Colors.primary,
        }}
      >
        <Link
          style={{ color: Colors.white, paddingBottom: 20 }}
          to={`/contactUs`}
        >
          Contact Us
        </Link>
        <Link style={{ color: Colors.white, paddingBottom: 20 }} to={`/eula`}>
          End User License Agreement
        </Link>
        <Link style={{ color: Colors.white, paddingBottom: 20 }} to={`/gdpr`}>
          GDPR Privacy Policy
        </Link>

        <Link
          style={{ color: Colors.white, paddingBottom: 20 }}
          to={`/changeLog`}
        >
          Change Log
        </Link>
      </div>
      <div
        style={{
          backgroundColor: Colors.primary,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <p
          style={{
            color: Colors.white,
            textAlign: "center",
            marginRight: 20,
            fontSize: 12,
          }}
        >
          Copyright © 2023, All Rights Reserved
        </p>
      </div>
    </div>
  );
}

function ContactUsForm() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        paddingLeft: 40,
        paddingRight: 40,
        alignItems: "center",
      }}
    >
      <h2 style={{ alignSelf: "center", color: Colors.primary_dark }}>
        Contact us with any suggestions, questions, or comments!
      </h2>

      <p
        style={{
          fontSize: 20,
          marginTop: 30,
          color: Colors.primary_dark,
          textAlign: "center",
        }}
      >
        Send us a message and we will get back to you as soon as we can!
      </p>
      <a href="mailto:reedbookapp@gmail.com">
        <h3>Click here to send us an email</h3>
      </a>

      <p>Email: reedbookapp@gmail.com</p>
    </div>
  );
  const [name, setName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [message, setMessage] = useState("");

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <label>
          Name:
          <input value={name} onChange={(e) => setName(e.target.value)} />
        </label>
        <label>
          Email address:
          <input
            style={{ marginLeft: 20 }}
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
            type="email"
          />
          <button onClick={() => onSubmit}>Submit</button>
        </label>

        <div style={{ display: "flex", alignItems: "center" }}>
          <label>
            Message:
            <textarea
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </label>
        </div>
      </div>
    </>
  );
}
